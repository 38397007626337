<template>
    <div class="container">
        <div class="columns is-centered content is-small success" v-if="resetSuccess">
            <h2 class="mb-5 ">{{ $t('Reset Success!')}}</h2>
            <div class="">
                <router-link class="btn btn-dark" to="/login">Login</router-link>
            </div>
        </div>
        <div class="row justify-content-center" v-else>
            <div class="col-md-8">
                <div class="card">
                    <h2 class="h2 card-header ">{{ $t('New password set')}}</h2>
                    <div class="card-body">
                        <form>
                            <div class="row justify-content-center">
                                <base-input class="form-group col-md-6" v-model.trim="formData.password" type="password"
                                            isPassword="true" :placeholder="$t('Password')"
                                            autocomplete="new-password"
                                            :error="errors.passwordError"></base-input>
                            </div>
                            <div class="row justify-content-center">
                                <base-input class="form-group col-md-6" v-model.trim="formData.passwordConfirmation"
                                            type="password" isPassword="true" :placeholder="$t('Confirm Password')"
                                            autocomplete="current-password"
                                            :error="errors.passwordConfirmationError"></base-input>
                            </div>
                        </form>
                        <div class="row justify-content-center">
                            <button class="btn btn-dark m-4" @click="resetRequest">
                                {{ $t('New password set')}}
                            </button>
                        </div>
                        <div class="button-colors">
                            <p> {{ $t('Don`t have an account yet?') }} </p>
                            <router-link class="link" to="/register">{{ $t('Register')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import {ERROR} from "../const";
    import BaseInput from "../components/base-components/BaseInput";

    export default {
        name: "PasswordResetRequest",
        components: {BaseInput},
        data() {
            return {
                loading: false,
                additionalParamsToErrormessage: null,
                formData: {
                    password: '',
                    passwordConfirmation: '',
                },
                errors: {
                    passwordError: '',
                    passwordConfirmationError: '',
                },
                resetSuccess: false
            }
        },
        methods: {
            ...mapActions(['DO_PASSWORD_RESET']),
            resetRequest() {
                this.clearErrors();
                if (this.formData.password.length < 8 || this.formData.passwordConfirmation.length < 8) {
                    this.loading = false;
                    this.formData.password.length < 8 ? this.errors.passwordError = 'The password is too short' : null;
                    this.formData.passwordConfirmation.length < 8 ? this.errors.passwordConfirmationError = 'The password is too short' : null;
                    return;
                } else if (this.formData.password != this.formData.passwordConfirmation) {
                    this.loading = false;
                    this.errors.passwordError = 'The password confirmation does not match';
                    this.errors.passwordConfirmationError = 'The password confirmation does not match';
                    return;
                }

                this.loading = true;
                this.DO_PASSWORD_RESET({
                    email: this.$route.query.email,
                    signature: this.$route.query.signature,
                    expires: this.$route.query.expires,
                    password: this.formData.password,
                    password_confirmed: this.formData.passwordConfirmation,

                })
                    .then(() => {
                        this.loading = false;
                        this.clearErrors();
                        this.resetSuccess = true;
                    })
                    .catch((data) => {
                        this.loading = false;

                        if (data && data.error && data.message) {
                            if (data.message === ERROR.RESET_PSWD_KEY_EXPIRED) {
                                this.errors.passwordConfirmationError = "Reset password key expired.";
                                this.errors.passwordError = "Reset password key expired.";
                            } else if (data.message === ERROR.RESET_PSWD_FAILED) {
                                this.errors.passwordConfirmationError = "Reset password failed.";
                                this.errors.passwordError = "Reset password failed.";
                            } else if (data.message === ERROR.USER_NOT_FOUND) {
                                this.errors.passwordConfirmationError = "User not found.";
                            }

                        }
                    })
            },
            clearErrors() {
                this.errors.passwordConfirmationError = '';
                this.errors.passwordError = '';
            },

        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/colors";
    .container {
        margin: 150px auto;
    }
    .link {
        color: $link-accent;
    }
    .entertainment{
        body{
            background-color: black!important;
        }
        .success{
            padding-top: 10rem;
            color: #F5F5F5;
        }
        .container {
            margin: 80px auto 0;
        }
        .card-header{
            color: #F5F5F5;
        }
        .card{
            margin-top: 10rem;
            background-color: #080809;
            .button-colors{
                color: #d0d0d0;
            }
        }
        .card-header{
            background-color: #060607;
        }
        .link {
            color: #FFFFFF;
            border-color: #1d2124;
        }
    }
</style>
